/**
 * Copyright 2018 SlidesUp. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
export class Track {
    id;
    name;
    sortOrder;
    description;

    /**
     * @param {string} id
     * @param {string} name
     * @param {number} sortOrder
     * @param {string} [description] - This is an HTML string
     */
    constructor(id, name, sortOrder, description) {
        this.id = id;
        this.name = name;
        this.sortOrder = sortOrder;
        this.description = description;
    }

    static toModel(id, jsTrack) {
        const { name, sortOrder, description } = jsTrack;

        return new Track(id, name, sortOrder, description);
    }
}
